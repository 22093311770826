import React from 'react';
import styled from 'styled-components';
import { Button, CircularProgress, Fab, IconButton, MobileStepper, Pagination, Step, StepContent, StepLabel, Stepper, Typography } from '@mui/material';
import { GoogleMap, MarkerF, useLoadScript } from '@react-google-maps/api';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

import { useParams } from 'react-router-dom';
import { Shipment, getShipment,FirebaseMovement,trackingDoc, MovementAction } from '../../Firebase/Firestore/Shipments';
import { collection, doc, getDoc, getDocs, orderBy, query } from 'firebase/firestore';
import { firestore } from '../../Firebase/Firebase';
import Loading from '../Loading/Loading';
import { mapOptions } from '../POSTGITEXT-NewShipment/Components/ShipmentForm';
import { HeadingStyle, SectionHeadingStyle, SubTextStyle } from '../POSTGITEXT-NewShipment/Styles/NewShipmentStyles';
import "./History.css";
import CloseIcon from '@mui/icons-material/Close';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';

export default function History(){
  const {URLShipment}=useParams();
  const [pageLoading,setPageLoading]=React.useState<boolean>(true);
  const [bodyLoading,setBodyLoading]=React.useState<boolean>(true);
  const [shipment,setShipment]=React.useState<Shipment | null >(null);
  const [selectedTruckIndex,setSelectedTruckIndex]=React.useState<number>(0);
  const [selectedTruckDoc,setSelectedTruckDoc]=React.useState<(trackingDoc &{docID:string}) | null>();
  const [movements,setMovements]=React.useState<FirebaseMovement[]>([]);
  const [selectedMovement,setSelectedMovement]=React.useState<FirebaseMovement | null>(null);
  const [selectedAction,setSelectedAction]=React.useState<MovementAction | null>(null);
  console.log("shipment",shipment);
  console.log("selectedTruckDoc",selectedTruckDoc);
  console.log("movements",movements);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_CLOUD_API_KEY as string,
  });
  const center = React.useMemo<{ lat: number; lng: number }>(
    () => ({
      lat: 26.087545,
      lng: 50.549084,
    }),
    []
  );
  const mapRef=React.useRef<google.maps.Map|null>(null);

  React.useEffect(()=>{
    if(!URLShipment || URLShipment===""){
      alert("ops shipment not found");
      return;
    }
    getShipment({shipmentId:URLShipment}).then((res)=>{
      if(res.success){
        setShipment(res.shipment);
      }
      else{
        alert(`ops something went wrong: ${res.error}`);
      }
    }).catch((err)=>{alert(`ops something went wrong: ${err}`)}).finally(()=>{setPageLoading(false)});
  },[URLShipment]);
  React.useEffect(()=>{
    if(shipment){
      const trackingDocRef=doc(collection(firestore,"Tracking"),shipment.trucks[selectedTruckIndex].trackingId);
      getDoc(trackingDocRef).then((res)=>{
        if(res.exists()){
          setSelectedTruckDoc({...res.data(),docID:res.id} as (trackingDoc&{docID:string}));
          const movementCollectionRef=collection(firestore,`Tracking/${shipment.trucks[selectedTruckIndex].trackingId}/Movements`);
          const q=query(movementCollectionRef,orderBy("index","asc"));
          getDocs(q).then((res)=>{
            const movements:FirebaseMovement[]=[];
            res.forEach((doc)=>{
              movements.push(doc.data() as FirebaseMovement);
            })
            setMovements(movements);
          }).catch((err)=>{alert(`ops something went wrong: ${err}`)})
        }
        else{
          alert("ops something went wrong_2");
        }
      }).catch((err)=>{alert(`ops something went wrong: ${err}`)}).finally(()=>{setBodyLoading(false)});
    }
  },[shipment,selectedTruckIndex]);
React.useEffect(() => {
  console.log("selectedMovement",selectedMovement);
  console.log("selectedAction",selectedAction);
  if (selectedAction && selectedMovement) {
    mapRef.current?.panTo({ lat: selectedAction.coords.lat, lng: selectedAction.coords.lng });
    const zoomLevel = mapRef.current?.getZoom();
    if (zoomLevel && zoomLevel < 17) {
      mapRef.current?.setZoom(17);
    } 
  }
    
    else if (selectedMovement) {
      mapRef.current?.panTo({ lat: selectedMovement.facility.coordinates.lat, lng: selectedMovement.facility.coordinates.lng});
      const zoomLevel = mapRef.current?.getZoom();
      if (zoomLevel && zoomLevel < 15) {
        mapRef.current?.setZoom(15);
      }
    }

    else if(selectedMovement===null && selectedAction===null){
      const zoomLevel = mapRef.current?.getZoom();
      if (zoomLevel && zoomLevel > 10) {
        mapRef.current?.setZoom(10);
      }

    }
  
}, [selectedMovement, selectedAction]);

  React.useEffect(()=>{

  },[selectedMovement,selectedAction]);
  return (
    <>
      {" "}
      {!pageLoading && shipment ? (
        <LocalMainContainer>
          <Typography style={HeadingStyle} color="primary">
            Shipment Details
          </Typography>

          <CenteringContainer>
            <PaperContainer>
              <DetailsContainer style={{ borderBottom: "3px solid black", width: "100%", paddingBottom: "1rem", gap: "1.5rem" }}>
                <DetailsSegment>
                  <Typography style={SectionHeadingStyle}>Shipment Date & Time</Typography>
                  <Typography style={SubTextStyle}>{shipment.dateTime.format("D-MMM-YYYY hh:mm A")}</Typography>
                </DetailsSegment>
                <DetailsSegment>
                  <Typography style={SectionHeadingStyle}>Total Number Trucks</Typography>
                  <Typography style={SubTextStyle}>{shipment.trucks.length}</Typography>
                </DetailsSegment>
              </DetailsContainer>
              {!bodyLoading && selectedTruckDoc && movements.length !== 0 ? (
                <>
                  <DetailsContainer style={{ width: "100%" }}>
                    {isLoaded ? (
                      <div style={{display:"flex",flexDirection:"column",width:"50%",gap:"0.5rem",position:"relative"}}>
                      <GoogleMap
                        onLoad={(map) => {
                          mapRef.current = map;
                        }}
                        center={center}
                        mapContainerStyle={{
                          width: "100%",
                          height: "30rem",
                          borderRadius: "10px 10px 0 0",
                        }}
                        options={mapOptions}
                      >
                        {selectedTruckDoc && movements.length !== 0 ? (
                          selectedAction && selectedMovement ? (
                            <>
                              <MarkerF
                                key={selectedMovement.index}
                                position={{
                                  lat: selectedMovement.facility.coordinates.lat,
                                  lng: selectedMovement.facility.coordinates.lng,
                                }}
                                icon={{
                                  path: google.maps.SymbolPath.CIRCLE,
                                  scale: 10,
                                  fillColor: "#1c2a57",
                                  fillOpacity: 1,
                                  strokeWeight: 0.4,
                                }}
                                label={{
                                  text: `${(selectedMovement.index + 1).toString()}`,
                                  color: "#fff",
                                }}
                              />
                              <MarkerF
                                key={selectedAction.dateTime + selectedAction.action + selectedAction.container}
                                position={{
                                  lat: selectedAction.coords.lat,
                                  lng: selectedAction.coords.lng,
                                }}
                                label={{
                                  text: selectedAction.action,
                                  color: "black",
                                  fontSize: "0.7rem",
                                }}
                                title={selectedAction.action}
                              />
                            </>
                          ) : selectedMovement && selectedMovement.actionLog ? (
                            selectedMovement.actionLog.map((action, index) => {
                              return (
                                <>
                                  <MarkerF
                                    key={selectedMovement.index}
                                    position={{
                                      lat: selectedMovement.facility.coordinates.lat,
                                      lng: selectedMovement.facility.coordinates.lng,
                                    }}
                                    icon={{
                                      path: google.maps.SymbolPath.CIRCLE,
                                      scale: 10,
                                      fillColor: "#1c2a57",
                                      fillOpacity: 1,
                                      strokeWeight: 0.4,
                                    }}
                                    label={{
                                      text: `${(selectedMovement.index + 1).toString()}`,
                                      color: "#fff",
                                    }}
                                  />
                                  <MarkerF
                                    zIndex={index}
                                    key={action.dateTime + action.action + action.container}
                                    position={{
                                      lat: action.coords.lat,
                                      lng: action.coords.lng,
                                    }}
                                    label={{
                                      text: action.action,
                                      color: "black",
                                      fontSize: "0.7rem",
                                    }}
                                    title={action.action}
                                  />
                                </>
                              );
                            })
                          ) : (
                            movements.map((movement, index) => {
                              return (
                                <MarkerF
                                  zIndex={index}
                                  key={index}
                                  position={{
                                    lat: movement.facility.coordinates.lat,
                                    lng: movement.facility.coordinates.lng,
                                  }}
                                  icon={{
                                    path: google.maps.SymbolPath.CIRCLE,
                                    scale: 10,
                                    fillColor: "#1c2a57",
                                    fillOpacity: 1,
                                    strokeWeight: 0.4,
                                  }}
                                  label={{
                                    text: `${(index + 1).toString()}`,
                                    color: "#fff",
                                  }}
                                />
                              );
                            })
                          )
                        ) : null}
                      </GoogleMap>
                      <Fab
                        variant="extended"
                        color="primary"
                        style={{width:"fit-content",position:"absolute",bottom:"-4rem"}}
                        onClick={() => {
                          window.open(`/LocationLog/${selectedTruckDoc?.docID}`, "_blank");
                        }}
                        
                      >
                        <GpsFixedIcon /> Driver location log
                      </Fab>
                      </div>
                      
                    ) : (
                      <div
                        style={{
                          width: "50%",
                          height: "30rem",
                          borderRadius: "10px 10px 0 0",
                          backgroundColor: "lightGrey",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <CircularProgress />
                      </div>
                    )}

                    <DetailsColumn style={{ width: "50%" }}>
                      <div style={{ display: "flex", gap: "1rem", width: "100%" }}>
                        <div style={{ display: "flex", flexDirection: "column", width: "fit-content" }}>
                          <Typography style={SectionHeadingStyle}>Truck Type</Typography>
                          <Typography style={SubTextStyle}>{selectedTruckDoc.type}</Typography>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", width: "fit-content" }}>
                          <Typography style={SectionHeadingStyle}>Carrier Id</Typography>
                          <Typography style={SubTextStyle}>{selectedTruckDoc.carrierId}</Typography>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", width: "fit-content" }}>
                          <Typography style={SectionHeadingStyle}>Driver Details</Typography>
                          <Typography style={SubTextStyle}>
                            {selectedTruckDoc.driverName} , {selectedTruckDoc.driverMobile}
                          </Typography>
                        </div>
                      </div>


                      <div style={{ minWidth: "35rem", maxHeight: "25rem", overflowY: "scroll", border: "solid black 1px", padding: "0 1rem 0 1rem", borderRadius: "0.5rem" }}>
                        <Typography variant="body2" fontSize={"0.9rem"} color="grey">
                          {" "}
                          {selectedTruckDoc.startDateTime?`Driver started at ${selectedTruckDoc.startDateTime?.toDate().toLocaleString("en-US", {
                            day: "numeric",
                            month: "short",
                            year: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            hour12: true,
                          })}`:"Driver never started this job"}
                        </Typography>
                        <Stepper activeStep={-1} orientation="vertical" nonLinear>
                          {movements.map((movement: FirebaseMovement, index) => (
                            <Step key={index} expanded active={movement.index === selectedMovement?.index}>
                              <StepLabel
                                className="hoverable"
                                style={{ position: "relative" }}
                                onClick={() => {
                                  if (movement.index !== selectedMovement?.index) {
                                    setSelectedMovement(movement);
                                    setSelectedAction(null);
                                  } else {
                                    setSelectedMovement(null);
                                    setSelectedAction(null);
                                  }
                                }}
                              >
                                {" "}
                                {movement.facility.name} ,{movement.facility.selectedSequanceModifier}
                                <Typography variant="body2" fontSize={"0.7rem"}>
                                  {" "}
                                  {movement.addedAt
                                    ? "added at " +
                                      movement.addedAt.toDate().toLocaleString("en-GB", {
                                        day: "numeric",
                                        month: "short",
                                        year: "numeric",
                                        hour: "numeric",
                                        minute: "numeric",
                                        hour12: false,
                                      })
                                    : "added on shipmint creation"}
                                </Typography>
                                {!movement.completed && (
                                  <Typography variant="body2" fontSize={"0.7rem"} color="error" fontWeight="bold">
                                    incomplete
                                  </Typography>
                                )}
                              </StepLabel>
                              <StepContent>
                                <div style={{ display: "flex", gap: "0.5rem" }}>
                                  {movement.containers.dropoff.length !== 0 && (
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                      <Typography key={index} variant="caption" fontWeight="bold">
                                        Drop-off
                                      </Typography>
                                      {movement.containers.dropoff.map((container, index) => {
                                        return (
                                          <Typography key={index} variant="caption" color="green" component="div" style={{display:"flex",flexDirection:"column"}}>
                                            {/* {container.confirmedSelection || container.confirmedByDriver ? ` ${container.number}` : container.BLRef ? `Container W/ B/L ${container.BLRef}` : `Container w/ CRF ${container.CRFRef}`} */}
                                            {(container.confirmedSelection || container.confirmedByDriver) && container.number? container.number : "No Container Number"}
                                            <Typography color="grey" variant='caption' fontSize="0.7rem">{container.BLRef ? `B/L: ${container.BLRef}`: container.CRFRef?`CRF: ${container.CRFRef}`:""}</Typography>
                                            </Typography>
                                        );
                                      })}
                                    </div>
                                  )}
                                  {movement.containers.pickup.length !== 0 && (
                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start" }}>
                                      <Typography key={index} variant="caption" fontWeight="bold">
                                        Pickup
                                      </Typography>
                                      {movement.containers.pickup.map((container, index) => {
                                        return (
                                          <Typography key={index} variant="caption" color="green" component="div" style={{display:"flex",flexDirection:"column"}}>
                                          {/* {container.confirmedSelection || container.confirmedByDriver ? ` ${container.number}` : container.BLRef ? `Container W/ B/L ${container.BLRef}` : `Container w/ CRF ${container.CRFRef}`} */}
                                          {(container.confirmedSelection || container.confirmedByDriver) && container.number? container.number : "No Container Number"}
                                          <Typography color="grey" variant='caption' fontSize="0.7rem">{container.BLRef ? `B/L: ${container.BLRef}`: container.CRFRef?`CRF: ${container.CRFRef}`:""}</Typography>
                                          </Typography>
                                        );
                                      })}
                                    </div>
                                  )}
                                  {movement.actionLog && movement.actionLog.length !== 0 && (
                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start" }}>
                                      <Typography key={index} variant="caption" fontWeight="bold">
                                        Actions
                                      </Typography>
                                      {movement.actionLog.map((action, index) => {
                                        return (
                                          <Typography
                                            key={index}
                                            variant="caption"
                                            fontWeight={selectedAction?.action === selectedAction?.action && selectedAction?.container === selectedAction?.container && selectedAction?.dateTime === action.dateTime ? "bold" : ""}
                                            color={selectedAction?.action === selectedAction?.action && selectedAction?.container === selectedAction?.container && selectedAction?.dateTime === action.dateTime ? "primary" : "text.secondary"}
                                            className="hoverable"
                                            onClick={() => {
                                              if (selectedAction?.action === selectedAction?.action && selectedAction?.container === selectedAction?.container && selectedAction?.dateTime === action.dateTime) {
                                                setSelectedAction(null);
                                              } else {
                                                setSelectedAction(action);
                                                setSelectedMovement(movement);
                                              }
                                            }}
                                          >
                                            {movement.facility.selectedSequanceModifier
                                              ? action.container
                                                ? `
                                                                                     ${action.action} ${
                                                    action.container.confirmedSelection || action.container.confirmedByDriver
                                                      ? ` ${action.container.number}`
                                                      : action.container.BLRef
                                                      ? `Container W/ B/L ${action.container.BLRef}`
                                                      : `Container w/ CRF ${action.container.CRFRef}`
                                                  } @ ${action.dateTime.toDate().toLocaleTimeString()}
                                                                                     `
                                                : `${action.action} @ ${action.dateTime.toDate().toLocaleTimeString()}`
                                              : `${action.action} ${action.container ? ` ${action.container.number} from` : ""} ${movement.facility.name} @ ${action.dateTime.toDate().toLocaleTimeString()}`}
                                          </Typography>
                                        );
                                      })}
                                    </div>
                                  )}
                                </div>
                              </StepContent>
                            </Step>
                          ))}
                        </Stepper>
                      </div>
                    </DetailsColumn>
                  </DetailsContainer>
                  <MobileStepper
                    style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}
                    variant="text"
                    steps={shipment.trucks.length}
                    position="static"
                    activeStep={selectedTruckIndex}
                    nextButton={
                      <Button
                        size="small"
                        onClick={() => {
                          setSelectedTruckIndex((prevSelected) => {
                            return prevSelected + 1;
                          });
                        }}
                        disabled={selectedTruckIndex === shipment.trucks.length - 1}
                        style={{ paddingLeft: "1rem" }}
                      >
                        Next
                        <KeyboardArrowRight />
                      </Button>
                    }
                    backButton={
                      <Button
                        size="small"
                        style={{ paddingRight: "1rem" }}
                        onClick={() => {
                          setSelectedTruckIndex((prevSelected) => {
                            return prevSelected - 1;
                          });
                        }}
                        disabled={selectedTruckIndex === 0}
                      >
                        <KeyboardArrowLeft />
                        Back
                      </Button>
                    }
                  />
                </>
              ) : (
                <Loading opacity={1} />
              )}
              <ActionsDiv></ActionsDiv>
            </PaperContainer>
          </CenteringContainer>
        </LocalMainContainer>
      ) : (
        <Loading fullscreen opacity={1} />
      )}
    </>
  );
} 

const LocalMainContainer=styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
width: 100%;
height: 100%;
gap: 0.5rem;
`;
const CenteringContainer=styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 100%;
height: 100%;
`;
const PaperContainer=styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
width: 100%;
height: 100%;
padding: 1rem;
gap: 1rem;
background-color: white;
border-radius: 10px;`;
const DetailsContainer=styled.div`
display: flex;
gap: 1rem;
`;
const DetailsColumn=styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
gap: 1rem;
`;
const DetailsSegment=styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
`;
const ActionsDiv=styled.div`
display: flex;
flex-direction: row;
justify-content: flex-end;
align-items: center;
gap: 1rem;
width: 100%;
`;